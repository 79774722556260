import { graphql, useStaticQuery } from "gatsby";

export const useAboutPageContent = () => {
    const aboutPages = useStaticQuery(graphql`
        query {
            allContentfulAboutPage(limit: 1) {
                edges {
                    node {
                        about {
                            raw
                        }
                        aboutPageHeading
                        aboutPageImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }`
    );

    return aboutPages.allContentfulAboutPage.edges[0].node;
}