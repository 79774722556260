import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useAboutPageContent } from "../hooks/useAboutPageContent";
import {
    aboutPageImageContent
} from "./AboutPageContent.module.css"
import RichTextContent from "./RichTextBlock";

const AboutPageHeading = ({content}) => {
    return <h1>{content}</h1>
}
  
const AboutPageImageContent = ({content}) => {
    return content ? 
        <GatsbyImage
            alt={`Image of Liz Green`}
            image={content.gatsbyImageData}
            className={aboutPageImageContent}
        /> :
        '';
}

const AboutPageContent = () => {
    const content = useAboutPageContent();

    return (
        <div>
            <AboutPageHeading content={content.aboutPageHeading}/>
            <AboutPageImageContent content={content.aboutPageImage}/>
            <RichTextContent content={content.about}/>
        </div>
    );
}

export default AboutPageContent;